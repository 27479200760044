<template>
  <v-textarea
    v-model="control.model[control.name]"
    v-bind="control"
    class="jexy-textarea"
  />
</template>

<script>

// Mixin
import Control from './Mixin'

export default {
  name: 'Textarea',
  mixins: [ Control ]
}

</script>

<style lang="scss">

.jexy-textarea textarea {
    padding-top: 3px;
  }

</style>
